import { isEqual } from 'lodash'
import { RouteLocationNormalized } from 'vue-router'
import { companyQueryKey } from '@/modules/actions/composable/useActionFilterCompany'
import { dateRangeQueryKey } from '@/modules/actions/composable/useActionFilterDateRange'
import { showInactiveQueryKey } from '@/modules/actions/composable/useActionFilterShowInactive'
import { modalQueryKey } from '@/modules/base/composable/useModalView'
import { searchQueryKey } from '@/modules/base/composable/useSearch'

export const preventScroll = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const prevent =
    preventSameRoute(to, from) ||
    preventScrollForQueries(to, from, [
      modalQueryKey,
      searchQueryKey,
      companyQueryKey,
      dateRangeQueryKey,
      showInactiveQueryKey,
    ]) ||
    preventScrollBetweenChildren(to, from)

  return prevent ? {} : undefined
}

export const preventSameRoute = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  return isEqual(to, from)
}

const preventScrollForQueries = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  queryKeys: string[]
): boolean => {
  const isNameEqual = to.name === from.name

  const isAnyQueryDefinedAndDifferent = queryKeys.some((key) => {
    const toQuery = to.query[key]
    const fromQuery = from.query[key]
    return (toQuery || fromQuery) && toQuery !== fromQuery
  })

  return isNameEqual && isAnyQueryDefinedAndDifferent
}

export const preventScrollBetweenChildren = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
): boolean => {
  const ancestorsPreventingScroll = from.matched.filter(
    (route) => route.meta.preventScrollBetweenChildren
  )

  const hasCommonAncestorsPreventingScroll = to.matched.some((route) =>
    ancestorsPreventingScroll.some((ancestor) => ancestor.name === route.name)
  )

  return hasCommonAncestorsPreventingScroll
}
